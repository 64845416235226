export const INIT_USER = "INIT_USER";
export const DATA = "DATA";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const WALLETDETAILS = "WALLETDETAILS";
export const ADDCART = "ADDCART";
export const TICKET_SEEN = "TICKET_SEEN";
export const TICKET_BADGE_NUMBER = "TICKET_BADGE_NUMBER";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_SEEN_ANNOUNCEMENTS = "UPDATE_SEEN_ANNOUNCEMENTS";

export const initUser = (user) => {
  return {
    type: INIT_USER,
    user,
  };
};


export const updateUserData = (payload) => {
  return {
    type: UPDATE_USER_DATA,
    payload,
  };
};

export const setData = (data) => ({
  type: DATA,
  data,
});

export const showModal = (modal, props = undefined) => ({
  type: SHOW_MODAL,
  modal,
  props,
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});

export const walletDetails = (details) => {
  return {
    type: WALLETDETAILS,
    details,
  };
};

export const addCart = (item) => {
  return {
    type: ADDCART,
    item,
  };
};

export const ticketSeen = (bool) => {
  return {
    type: TICKET_SEEN,
    bool,
  };
};

export const updateTicketBadgeNumber = (payload) => {
  return {
    type: TICKET_BADGE_NUMBER,
    payload,
  };
};

export const updateAnnouncements = (payload) => {
  return {
    type: UPDATE_SEEN_ANNOUNCEMENTS,
    payload,
  };
};
