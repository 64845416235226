import {
  INIT_USER,
  UPDATE_USER_DATA,
  UPDATE_SEEN_ANNOUNCEMENTS,
} from "../actions";
import { PURGE } from "redux-persist";

export const UserInit = {
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  mobile: null,
  phone: null,
  image: null,
  token: null,
  plan_id: 2,
  email_status: "Inactive",
  mobile_status: "Inactive",
  status: "Inactive",
  otp: "Inactive",
  profile_picture: null,
  identity_attachment: null,
  sms_notification: "Inactive",
  email_notification: "Inactive",
  ref_currency_id: null,
  timezone_id: null,
  renew: "Inactive",
  created_at: "2000-01-01 12:00:00",
  updated_at: "2000-01-01 12:00:01",
  username: "",
  strength: 0,
  promotion_code: "",
  national_code: "",
  announcementsData: {
    announcements: [],
    unseen: 0,
  },
};

const userReducer = (state = UserInit, action) => {
  switch (action.type) {
    case INIT_USER:
      return { ...state, ...action.user };

    case UPDATE_USER_DATA:
      return { ...state, ...action.payload };

    case UPDATE_SEEN_ANNOUNCEMENTS:
      const updatedData = state.announcementsData.announcements?.map((item) => {
        if (item.id === action.payload) item.seen = 1;
        return item;
      });
      const updatedBadge = state.announcementsData.unseen - 1;
      return {
        ...state,
        announcementsData: { announcements: updatedData, unseen: updatedBadge },
      };

    case PURGE:
      return UserInit;

    default:
      return state;
  }
};

export default userReducer;
