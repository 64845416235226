import { TICKET_BADGE_NUMBER } from "../actions";

const initialState = {
  ticket: 0,
  announce: 0,
};

const badgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TICKET_BADGE_NUMBER:
      return { ...state, ticket: action.payload };

    default:
      return state;
  }
};
export default badgeReducer;
