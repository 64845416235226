import intlActionType from "./intlActionType";

const INITIAL_STATE = {
  DirectionRtl: false,
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
  },
};

const intlReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case intlActionType.SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };

    case intlActionType.SWITCH_DIRECTION:
      return {
        ...state,
        DirectionRtl: !state.DirectionRtl,
      };
    default:
      return state;
  }
};
export default intlReducer;
