const INITIAL_STATE = {
  modal: null,
  props: {},
};

function dialogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        ...state,
        modal: action.modal,
        props: action.props,
      };
    case "HIDE_MODAL":
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default dialogReducer;
