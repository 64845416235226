import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import { combineReducers } from "redux";
import userReducer from "./userReducer";
import dataReducer from "./dataReducer";
import dialogReducer from "./dialogReducer";
import walletDetailsReducer from "./walletDetailsReducer";
import CartReducer from "./CartReducer";
import intlReducer from "./intl/intlReducer";
import badgeReducer from "./badgeReducer";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["dialogReducer"],
};

const rootReducer = combineReducers({
  userReducer,
  dataReducer,
  dialogReducer,
  walletDetailsReducer,
  CartReducer,
  intlReducer,
  badgeReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);
